@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './assets/styles/snackbar.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

@include mat-core();
.dz-image img{
  width:auto !important;
  max-height:100% !important;
}
$brz-app-accent: mat-palette($mat-orange, 500);
$brz-app-primary: mat-palette($mat-gray, 900);
$brz-app-theme: mat-light-theme($brz-app-primary, $brz-app-accent);



$my-custom-level: mat-typography-config(  
  $font-family: 'Lato, monospace',  
  $title: mat-typography-level(2.0em, 32px, 500),
  $headline: mat-typography-level(32px, 48px, 700),  
  $body-1: mat-typography-level(16px, 24px, 500),  
  $body-2: mat-typography-level(16px, 24px, 400),

);  


// .mat-horizontal-stepper-header-container{
//   pointer-events: none !important;
//   display: none !important;
// }

$theme-or-config: mat.define-light-theme(
  (
      color: (
          primary: $brz-app-primary,
          accent: $brz-app-accent
      ),
      typography: $my-custom-level
  )
);

// @include angular-material-theme($theme-or-config);
@include mat.all-component-themes($theme-or-config);





.mat-card {
  box-shadow: none;
  background-color: #fafafa;
  margin: 24px 24px;
  padding: 16px;
}


.mat-expansion-panel-content{
.mdc-list-item__primary-text{
  color: #fff !important;
}}

.negative{
  background:rgba(255,0,0,.4) !important;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font: 500 1.6em Open Sans;
}

h1 {
  font: 500 1.6em Lato;
}

.h1-mobile {
  font: bold 1.6em Lato;
  opacity: 0.9;
}

mat-card-actions span {
  font: 500 1em Lato;
}



.mat-mdc-list-item-unscoped-content{
  color: #fff;
}

// mat-card-title {
//   font: 500 1.9em Lato !important;
// }

mat-label {
  font: 500 1em Lato !important;
}

mat-card h1 {
  font: 500 1em Lato !important;
}

mat-form-field {
  font: 500 1em Lato !important;
}

input {
  font: 500 1em Lato !important;
}

h2 {
  color: #666666;
  font: 500 1.6em Lato;
}

input {
  font: 500 1.6em Open Sans;
}

a {
  font: 500 1.6em Open Sans;
}

mat-panel-title {
  font: bold 1em Lato;
}

.mat-expansion-indicator::after {
  color: #ffffff !important;
}

.fix-search {
  background: url('./assets/images/fix-search.svg') no-repeat center;
  background-size: 20vh;
  height: 70vh;
}

.loading-container {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // background-color: rgba(0,0,0,0.7);
  z-index: 5;
}

.h-60 {
  height: 60vh;
}

.flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-shadow-gray {
  box-shadow: 0 10px 30px 0 rgba(83, 100, 121, 0.08) !important;
  transition: 1s !important;
}

.card-title {
  font: 500 1.3em Lato !important;
}

.flex-center {
  display: flex !important;
  align-items: center !important;
}

.flex-justify-center {
  display: flex !important;
  justify-content: center !important;
}

input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  display: none;
}

.highcharts-tooltip-box {
	fill: #dfdfdf;
	fill-opacity: 0.6;
	stroke-width: 0;
}

.highcharts-tooltip text {
	fill: white;
	text-shadow: 0 0 3px #dfdfdf;
}

a, a:visited, a:hover{
  font-size:1.0em !important;
}

.card-sms .chats{
  height: 62vh !important;
  position: relative !important;
  z-index: 0 !important;
  box-shadow: none !important;
  width: 80% !important ;
  background-color: #f5f5f5 !important;
  margin: 24px 24px !important;
  border-radius: 12px !important;
}

// ng-select

.ng-select-container{
  border-bottom: 1px solid #0000006b !important;
  background-color: #f5f5f5 !important;
  padding: 0 16px !important;
  width: 500px !important;
  height: 57px !important;
  font-size: 14px !important;
  color: #000 !important;
  border-left:none !important;
  border-top: none !important;
  border-right: none !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; 
  border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.ng-dropdown-panel{
  width: 500px !important;
}

.ng-placeholder{
  color: #000 !important;
  top: 20px !important;
}

.mat-grid-tile-content{
  display: flex;
  flex-direction: column;
  top: 100px !important;
}
.mat-mdc-list-item .mdc-list-item__primary-text{
  font-size: 14px !important;
}

.mat-grid-tile{

  overflow-y: auto !important;
}

.cdk-overlay-dark-backdrop{
  backdrop-filter: blur(8px) !important; /* Isso aplica o desfoque ao fundo */
}


.table-logs > tbody > tr:nth-child(1){
  background-color:#bcffb3 !important;
}